import React from "react";
import dare from "../assets/dare.JPG";
import TutorMatch from "../assets/TutorMatch.png";
import PinkDetect from "../assets/PinkDetect.png";

function Projects() {
  const styles = {
    container: {
      background: "linear-gradient(to bottom, #0d1117, #161b22)",
      color: "#c9d1d9",
      minHeight: "100vh",
      padding: "40px 20px",
      fontFamily: "'Roboto', sans-serif",
      textAlign: "center",
    },
    header: {
      marginBottom: "20px",
    },
    headerTitle: {
      fontSize: "2.5rem",
      marginBottom: "10px",
    },
    headerDescription: {
      fontSize: "1.2rem",
      color: "#8b949e",
    },
    projectList: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
      gap: "20px",
      paddingTop: "20px",
    },
    projectItem: {
      backgroundColor: "#21262d",
      borderRadius: "10px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
      padding: "20px",
      textAlign: "center",
      transition: "transform 0.3s, box-shadow 0.3s",
      cursor: "pointer",
    },
    projectItemHover: {
      transform: "scale(1.05)",
      boxShadow: "0 8px 15px rgba(0, 0, 0, 0.5)",
    },
    projectImage: {
      width: "100%",
      height: "auto", // Ensures the full image is displayed without cropping
      borderRadius: "8px",
      marginBottom: "15px",
    },
    projectName: {
      fontSize: "1.5rem",
      color: "#58a6ff",
    },
    projectDescription: {
      fontSize: "1rem",
      color: "#c9d1d9",
      marginBottom: "10px",
    },
    projectLink: {
      fontSize: "1rem",
      color: "#f78166",
      textDecoration: "none",
    },
  };

  const projects = [
    {
      name: "Pink Detect",
      image: PinkDetect,
      description:
        "An AI-powered solution for breast cancer detection. Leveraging deep learning for accurate predictions.",
      link: "https://www.linkedin.com/company/pinkdetect/?originalSubdomain=ca",
    },
    {
      name: "TutorMatch",
      image:
      TutorMatch,
      description:
        "A platform connecting students with expert tutors tailored to their needs. Enhancing personalized education.",
      link: "https://apps.apple.com/us/app/tutor-platform/id1250211933", 
    },
    {
      name: "Process Mining in Python",
      image:
      dare,
      description:
        "An analytical toolkit for uncovering process insights from event logs using Python. Supports optimization and monitoring.",
      link: "https://www.yorku.ca/laps/2023/02/09/laps-itec-professor-arik-senderovich-artificial-intelligence-and-process-mining/",
    },
  ];

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h1 style={styles.headerTitle}>My Personal Projects</h1>
        <p style={styles.headerDescription}>
          Explore some of the projects I've worked on. Each one highlights a unique skill set and creativity.
        </p>
      </div>
      <div style={styles.projectList}>
        {projects.map((project, idx) => (
          <div
            key={idx}
            style={styles.projectItem}
            onMouseEnter={(e) => {
              Object.assign(e.currentTarget.style, styles.projectItemHover);
            }}
            onMouseLeave={(e) => {
              Object.assign(e.currentTarget.style, styles.projectItem);
            }}
          >
            <img src={project.image} alt={project.name} style={styles.projectImage} />
            <h2 style={styles.projectName}>{project.name}</h2>
            <p style={styles.projectDescription}>{project.description}</p>
            <a href={project.link} target="_blank" rel="noopener noreferrer" style={styles.projectLink}>
              Learn More
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Projects;
