import React, { useState } from "react";

const ContactMe = () => {
  const [formData, setFormData] = useState({ name: "", email: "", message: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const mailtoLink = `mailto:sep123@my.yorku.ca?subject=Message from ${formData.name}&body=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\n\nMessage:\n${formData.message}`
    )}`;
    window.location.href = mailtoLink;
  };

  return (
    <div
      style={{
        background: "linear-gradient(to bottom, #000000, #c1c0c0)",
        color: "white",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        fontFamily: "'Roboto', sans-serif",
      }}
    >
      <style>
        {`
          /* Form Container */
          .contact-form {
            background: #1a1a1a;
            padding: 40px;
            border-radius: 15px;
            box-shadow: 0 8px 15px rgba(0, 0, 0, 0.7);
            max-width: 600px;
            width: 100%;
          }

          /* Form Title */
          .contact-form h1 {
            text-align: center;
            margin-bottom: 30px;
            color: #f2f2f2;
            font-size: 2rem;
            text-shadow: 0px 4px 8px rgba(255, 255, 255, 0.3);
          }

          /* Input Fields */
          .contact-form input,
          .contact-form textarea {
            width: 100%;
            padding: 15px;
            margin-bottom: 20px;
            border-radius: 10px;
            border: 1px solid #444;
            background-color: #333;
            color: white;
            font-size: 1rem;
            outline: none;
            transition: box-shadow 0.2s ease, border-color 0.2s ease;
          }

          /* Focus Effects */
          .contact-form input:focus,
          .contact-form textarea:focus {
            border-color: #4caf50;
            box-shadow: 0 0 10px rgba(76, 175, 80, 0.8);
          }

          /* Submit Button */
          .contact-form button {
            width: 100%;
            padding: 15px;
            border-radius: 10px;
            border: none;
            background: linear-gradient(to right, #4caf50, #2e8b57);
            color: white;
            font-size: 1.2rem;
            font-weight: bold;
            cursor: pointer;
            transition: transform 0.2s, box-shadow 0.2s;
          }

          /* Hover Effects for Button */
          .contact-form button:hover {
            transform: scale(1.05);
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
          }

          /* Responsive Design */
          @media (max-width: 768px) {
            .contact-form {
              padding: 20px;
            }

            .contact-form h1 {
              font-size: 1.5rem;
            }

            .contact-form button {
              font-size: 1rem;
            }
          }
        `}
      </style>
      <form className="contact-form" onSubmit={handleSubmit}>
        <h1>Contact Me</h1>
        <div>
          <label
            htmlFor="name"
            style={{
              display: "block",
              marginBottom: "10px",
              fontWeight: "bold",
              color: "#cccccc",
            }}
          >
            Name:
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label
            htmlFor="email"
            style={{
              display: "block",
              marginBottom: "10px",
              fontWeight: "bold",
              color: "#cccccc",
            }}
          >
            Email:
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label
            htmlFor="message"
            style={{
              display: "block",
              marginBottom: "10px",
              fontWeight: "bold",
              color: "#cccccc",
            }}
          >
            Message:
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            rows="5"
          ></textarea>
        </div>
        <button type="submit">Send</button>
      </form>
    </div>
  );
};

export default ContactMe;
