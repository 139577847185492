import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import SchoolIcon from "@mui/icons-material/School";
import WorkIcon from "@mui/icons-material/Work";

function Experience() {
  return (
    <div
      style={{
        background: "linear-gradient(to bottom, #000000, #c1c0c0)",
        color: "#fff",
        padding: "50px 0",
        minHeight: "100vh",
      }}
    >
      <h1
        style={{
          textAlign: "center",
          marginBottom: "40px",
          fontSize: "3rem",
          fontFamily: "'Orbitron', sans-serif",
          color: "transparent",
          backgroundImage: "linear-gradient(45deg, #f9a826, #ff6a88)",
          backgroundClip: "text",
          WebkitBackgroundClip: "text",
          textShadow: "0 4px 10px rgba(255, 255, 255, 0.3)",
          position: "relative",
        }}
      >
        My Experience
      </h1>
      <style>
        {`
          .experience h1::after {
            content: "";
            display: block;
            width: 200px;
            height: 4px;
            background: linear-gradient(90deg, #f9a826, #ff6a88);
            margin: 10px auto 0;
            border-radius: 2px;
            animation: glow 1.5s infinite alternate;
          }

          @keyframes glow {
            from {
              opacity: 0.8;
            }
            to {
              opacity: 1;
              box-shadow: 0 0 15px rgba(255, 255, 255, 0.8);
            }
          }

          .vertical-timeline-element {
            animation: fadeIn 1.2s ease-in-out;
          }

          @keyframes fadeIn {
            from {
              transform: translateY(50px);
              opacity: 0;
            }
            to {
              transform: translateY(0);
              opacity: 1;
            }
          }

          .vertical-timeline-element-content {
            border: 1px solid transparent;
            background: linear-gradient(to right, #1a1a2e, #2e2e48);
            border-radius: 15px;
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.7);
            overflow: hidden;
            transition: transform 0.3s ease, box-shadow 0.3s ease;
          }

          .vertical-timeline-element-content:hover {
            transform: scale(1.02);
            box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.9);
            border: 2px solid #f9a826;
          }

          .vertical-timeline-element-content-arrow {
            border-right: 7px solid #f9a826;
          }

          .vertical-timeline-element-title {
            color: #f9a826;
            font-weight: bold;
          }

          .vertical-timeline-element-subtitle {
            color: #e1e1e1;
          }

          .vertical-timeline-element-icon {
            background: linear-gradient(135deg, #3e497a, #2a3b5f);
            color: #fff;
            box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5);
            animation: rotateIcon 3s linear infinite;
          }

          @keyframes rotateIcon {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
      <VerticalTimeline lineColor="#f9a826">
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2021 - Present"
          contentStyle={{
            borderRadius: "15px",
          }}
          contentArrowStyle={{ borderRight: "7px solid #f9a826" }}
          iconStyle={{
            background: "linear-gradient(135deg, #3e497a, #2a3b5f)",
            color: "#fff",
          }}
          icon={<SchoolIcon />}
        >
          <h3 style={{ color: "#f9a826" }}>York University, Toronto, Canada</h3>
          <h4 style={{ color: "#e1e1e1" }}>Bachelor's Degree</h4>
          <p style={{ color: "#bfbfbf" }}>Information Technology</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="September 2022 - January 2023"
          contentStyle={{
            borderRadius: "15px",
          }}
          contentArrowStyle={{ borderRight: "7px solid #f9a826" }}
          iconStyle={{
            background: "linear-gradient(135deg, #e9d35b, #c8a345)",
            color: "#fff",
          }}
          icon={<WorkIcon />}
        >
          <h3 style={{ color: "#f9a826" }}>
            Software Developer Research Assistant - York University
          </h3>
          <h4 style={{ color: "#e1e1e1" }}>Toronto, Canada</h4>
          <p style={{ color: "#bfbfbf" }}>
            Developed the backend infrastructure for a project.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="January 2023 - June 2023"
          contentStyle={{
            borderRadius: "15px",
          }}
          contentArrowStyle={{ borderRight: "7px solid #f9a826" }}
          iconStyle={{
            background: "linear-gradient(135deg, #e9d35b, #c8a345)",
            color: "#fff",
          }}
          icon={<WorkIcon />}
        >
          <h3 style={{ color: "#f9a826" }}>
            Junior Full Stack Application Developer - Yu Ride (RydUp)
          </h3>
          <h4 style={{ color: "#e1e1e1" }}>Toronto, Canada</h4>
          <p style={{ color: "#bfbfbf" }}>
            Helped the team launch 2 major features by working both in the front
            end and back end.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="January 2023 - Present"
          contentStyle={{
            borderRadius: "15px",
          }}
          contentArrowStyle={{ borderRight: "7px solid #f9a826" }}
          iconStyle={{
            background: "linear-gradient(135deg, #e9d35b, #c8a345)",
            color: "#fff",
          }}
          icon={<WorkIcon />}
        >
          <h3 style={{ color: "#f9a826" }}>
            Full Stack Application Developer - SiMLQ
          </h3>
          <h4 style={{ color: "#e1e1e1" }}>Toronto, Canada</h4>
          <p style={{ color: "#bfbfbf" }}>
            Developed AI-driven process mining applications using React and
            Python. Addressed challenges in optimizing healthcare workflows and
            analyzing complex datasets. Designed and implemented new features,
            conducted iterative testing, and resolved bugs. Wrote automated
            tests to ensure reliability and maintainability. Collaborated with
            teams to deliver scalable and user-centric solutions.
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
}

export default Experience;
