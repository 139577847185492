const predefinedResponses = [

  {
    intent: [
      "who is sepehr",
      "tell me about sepehr",
      "tell me about yourself",
      "introduce yourself",
      "what is your name",
      "what's your name",
      "you name",
    ],
    response:
      "My name is Sepehr Fazely Petrodi. I am a Full Stack Software Engineer with expertise in web and mobile development. I specialize in Python, JavaScript, Swift, and modern frameworks like React and Django.",
  },
  {
    intent: [
      "what do you do",
      "describe your current job",
      "what is your role",
      "what does sepehr do",
      "what is sepehr's job",
      "sepehr's work",
      "what is his work",
    ],
    response:
      "I am a Full Stack Software Engineer at SiMLQ, where I develop process mining tools, collaborate with teams to optimize pipelines, and enhance user experiences with modern technologies.",
  },
  {
    intent: ["what are your strengths", "what is your biggest strength"],
    response:
      "One of my biggest strengths is my ability to analyze complex problems and deliver effective solutions. I’m also a strong team player who excels in collaborative environments.",
  },
  {
    intent: ["what is your weakness", "what are your weaknesses"],
    response:
      "My main weakness is work-life balance. I tend to work long hours because I love achieving goals and completing tasks. I'm working on setting boundaries to ensure a healthier balance.",
  },
  {
    intent: [
      "what are your technical skills",
      "list your skills",
      "what are sepehr's technical skills",
      "sepehr's tech stack",
    ],
    response:
      "I am proficient in Python, JavaScript, Swift, Java, and C#. My expertise includes frameworks like React, Angular, Django, and SwiftUI. I’m experienced with databases such as MongoDB, PostgreSQL, and MySQL, as well as DevOps tools like Docker and Git.",
  },
  {
    intent: [
      "what is your education background",
      "describe your education",
      "what is sepehr's educational background",
      "sepehr's education",
    ],
    response:
      "I am pursuing a Bachelor of Information Technology at York University, with a GPA of 3.7. I’ve received the Academic Excellence Award and the DARE Project Award for my research in queue mining.",
  },
  {
    intent: [
      "what is your most notable project",
      "tell me about a project you are proud of",
      "what is sepehr's most notable project",
      "sepehr's favorite project",
    ],
    response:
      "One of my most notable projects is my Queue Management Research project, where I developed a tool to optimize wait times in healthcare settings using Python, improving wait-time accuracy by 30%.",
  },
  {
    intent: [
      "how do you approach problem-solving",
      "describe your problem-solving approach",
      "how does sepehr approach problem-solving",
      "problem-solving",
    ],
    response:
      "I approach problem-solving methodically, analyzing data, identifying bottlenecks, and designing effective solutions. I rely on tools like process mining and simulation models to optimize outcomes.",
  },
  {
    intent: [
      "how do you handle deadlines",
      "working under pressure",
      "how does sepehr handle deadlines",
      "sepehr under pressure",
    ],
    response:
      "I prioritize tasks effectively and focus on high-impact deliverables. Using Agile methodologies and tools like Kanban boards, I ensure timely completion without compromising quality.",
  },
  {
    intent: [
      "how do you ensure code quality",
      "what is your coding approach",
      "how does sepehr ensure code quality",
      "code quality",
    ],
    response:
      "I ensure code quality through comprehensive testing, code reviews, and adherence to best practices. I also leverage version control tools like Git to maintain a clean and collaborative workflow.",
  },
  {
    intent: [
      "how do you stay updated with new technologies",
      "learning new tech",
      "how does sepehr keep up with new technologies",
    ],
    response:
      "I stay updated by participating in online courses, attending tech meetups, and exploring emerging technologies through personal projects and experimentation.",
  },
  {
    intent: [
      "what is your leadership experience",
      "describe your leadership roles",
      "what is sepehr's leadership experience",
      "leadership roles",
    ],
    response:
      "I served as the President of the Information Technology Club at York University, fostering academic and professional growth. I also mentor junior developers, sharing best practices and industry insights.",
  },
  {
    intent: [
      "how do you manage work-life balance",
      "balancing work and personal life",
      "does sepehr have work-life balance",
    ],
    response:
      "Balancing work and personal life has been a challenge for me. I tend to focus heavily on task completion, but I am actively working on setting boundaries and making time for self-care.",
  },
  {
    intent: [
      "what motivates you",
      "what drives you",
      "what motivates sepehr",
      "what drives sepehr",
    ],
    response:
      "I am motivated by solving complex problems and creating impactful solutions. I also enjoy learning and applying new technologies to make processes more efficient.",
  },
  {
    intent: [
      "describe your teamwork experience",
      "how do you collaborate",
      "what is sepehr's teamwork experience",
      "teamwork experience of sepehr",
    ],
    response:
      "I excel in teamwork by maintaining clear communication and ensuring alignment with shared goals. I’ve collaborated with DevOps teams and cross-functional stakeholders to deliver projects successfully.",
  },
];

export default predefinedResponses;
