import React from 'react';
import '../styles/SnakeGame.css';
import GameOver from './GameOver';

class SnakeGame extends React.Component {
  constructor(props) {
    super(props);

    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.state = {
      width: 0,
      height: 0,
      blockWidth: 0,
      blockHeight: 0,
      gameLoopTimeout: 240,
      timeoutId: 0,
      startSnakeSize: 0,
      snake: [],
      apple: {},
      direction: 'right',
      directionChanged: false,
      isGameOver: false,
      snakeColor: this.props.snakeColor || this.getRandomColor(),
      appleColor: this.props.appleColor || this.getRandomColor(),
      score: 0,
      highScore: Number(localStorage.getItem('snakeHighScore')) || 0,
      newHighScore: false,
    };
  }

  componentDidMount() {
    this.initGame();
    window.addEventListener('keydown', this.handleKeyDown);
    this.gameLoop();
  }

  initGame() {
    let percentageWidth = this.props.percentageWidth || 40;
    let width =
      document.getElementById('GameBoard').parentElement.offsetWidth *
      (percentageWidth / 100);
    width -= width % 30;
    if (width < 30) width = 30;
    let height = (width / 3) * 2;
    let blockWidth = width / 30;
    let blockHeight = height / 20;

    let startSnakeSize = this.props.startSnakeSize || 6;
    let snake = [];
    let Xpos = width / 2;
    let Ypos = height / 2;
    let snakeHead = { Xpos: width / 2, Ypos: height / 2 };
    snake.push(snakeHead);
    for (let i = 1; i < startSnakeSize; i++) {
      Xpos -= blockWidth;
      let snakePart = { Xpos: Xpos, Ypos: Ypos };
      snake.push(snakePart);
    }

    let appleXpos =
      Math.floor(Math.random() * ((width - blockWidth) / blockWidth + 1)) *
      blockWidth;
    let appleYpos =
      Math.floor(Math.random() * ((height - blockHeight) / blockHeight + 1)) *
      blockHeight;
    while (appleYpos === snake[0].Ypos) {
      appleYpos =
        Math.floor(Math.random() * ((height - blockHeight) / blockHeight + 1)) *
        blockHeight;
    }

    this.setState({
      width,
      height,
      blockWidth,
      blockHeight,
      startSnakeSize,
      snake,
      apple: { Xpos: appleXpos, Ypos: appleYpos },
    });
  }

  gameLoop() {
    let timeoutId = setTimeout(() => {
      if (!this.state.isGameOver) {
        this.moveSnake();
        this.tryToEatSnake();
        this.tryToEatApple();
        this.setState({ directionChanged: false });
      }

      this.gameLoop();
    }, this.state.gameLoopTimeout);

    this.setState({ timeoutId });
  }

  componentWillUnmount() {
    clearTimeout(this.state.timeoutId);
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  resetGame() {
    let width = this.state.width;
    let height = this.state.height;
    let blockWidth = this.state.blockWidth;
    let blockHeight = this.state.blockHeight;
    let apple = this.state.apple;

    let snake = [];
    let Xpos = width / 2;
    let Ypos = height / 2;
    let snakeHead = { Xpos: width / 2, Ypos: height / 2 };
    snake.push(snakeHead);
    for (let i = 1; i < this.state.startSnakeSize; i++) {
      Xpos -= blockWidth;
      let snakePart = { Xpos: Xpos, Ypos: Ypos };
      snake.push(snakePart);
    }

    apple.Xpos =
      Math.floor(Math.random() * ((width - blockWidth) / blockWidth + 1)) *
      blockWidth;
    apple.Ypos =
      Math.floor(Math.random() * ((height - blockHeight) / blockHeight + 1)) *
      blockHeight;
    while (this.isAppleOnSnake(apple.Xpos, apple.Ypos)) {
      apple.Xpos =
        Math.floor(Math.random() * ((width - blockWidth) / blockWidth + 1)) *
        blockWidth;
      apple.Ypos =
        Math.floor(Math.random() * ((height - blockHeight) / blockHeight + 1)) *
        blockHeight;
    }

    this.setState({
      snake,
      apple,
      direction: 'right',
      directionChanged: false,
      isGameOver: false,
      gameLoopTimeout: 70,
      snakeColor: this.getRandomColor(),
      appleColor: this.getRandomColor(),
      score: 0,
      newHighScore: false,
    });
  }

  getRandomColor() {
    let hexa = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) color += hexa[Math.floor(Math.random() * 16)];
    return color;
  }

  moveSnake() {
    let snake = this.state.snake;
    let previousPartX = this.state.snake[0].Xpos;
    let previousPartY = this.state.snake[0].Ypos;
    let tmpPartX = previousPartX;
    let tmpPartY = previousPartY;
    this.moveHead();
    for (let i = 1; i < snake.length; i++) {
      tmpPartX = snake[i].Xpos;
      tmpPartY = snake[i].Ypos;
      snake[i].Xpos = previousPartX;
      snake[i].Ypos = previousPartY;
      previousPartX = tmpPartX;
      previousPartY = tmpPartY;
    }
    this.setState({ snake });
  }

  tryToEatApple() {
    let snake = this.state.snake;
    let apple = this.state.apple;

    if (snake[0].Xpos === apple.Xpos && snake[0].Ypos === apple.Ypos) {
      let width = this.state.width;
      let height = this.state.height;
      let blockWidth = this.state.blockWidth;
      let blockHeight = this.state.blockHeight;
      let newTail = { Xpos: apple.Xpos, Ypos: apple.Ypos };
      let highScore = this.state.highScore;
      let newHighScore = this.state.newHighScore;
      let gameLoopTimeout = this.state.gameLoopTimeout;

      snake.push(newTail);

      apple.Xpos =
        Math.floor(Math.random() * ((width - blockWidth) / blockWidth + 1)) *
        blockWidth;
      apple.Ypos =
        Math.floor(Math.random() * ((height - blockHeight) / blockHeight + 1)) *
        blockHeight;
      while (this.isAppleOnSnake(apple.Xpos, apple.Ypos)) {
        apple.Xpos =
          Math.floor(Math.random() * ((width - blockWidth) / blockWidth + 1)) *
          blockWidth;
        apple.Ypos =
          Math.floor(
            Math.random() * ((height - blockHeight) / blockHeight + 1)
          ) * blockHeight;
      }

      if (this.state.score === highScore) {
        highScore++;
        localStorage.setItem('snakeHighScore', highScore);
        newHighScore = true;
      }

      if (gameLoopTimeout > 25) gameLoopTimeout -= 0.5;

      this.setState({
        snake,
        apple,
        score: this.state.score + 1,
        highScore,
        newHighScore,
        gameLoopTimeout,
      });
    }
  }

  tryToEatSnake() {
    let snake = this.state.snake;

    for (let i = 1; i < snake.length; i++) {
      if (snake[0].Xpos === snake[i].Xpos && snake[0].Ypos === snake[i].Ypos)
        this.setState({ isGameOver: true });
    }
  }

  isAppleOnSnake(appleXpos, appleYpos) {
    let snake = this.state.snake;
    for (let i = 0; i < snake.length; i++) {
      if (appleXpos === snake[i].Xpos && appleYpos === snake[i].Ypos)
        return true;
    }
    return false;
  }

  moveHead() {
    switch (this.state.direction) {
      case 'left':
        this.moveHeadLeft();
        break;
      case 'up':
        this.moveHeadUp();
        break;
      case 'right':
        this.moveHeadRight();
        break;
      default:
        this.moveHeadDown();
    }
  }

  moveHeadLeft() {
    let width = this.state.width;
    let blockWidth = this.state.blockWidth;
    let snake = this.state.snake;
    snake[0].Xpos =
      snake[0].Xpos <= 0 ? width - blockWidth : snake[0].Xpos - blockWidth;
    this.setState({ snake });
  }

  moveHeadUp() {
    let height = this.state.height;
    let blockHeight = this.state.blockHeight;
    let snake = this.state.snake;
    snake[0].Ypos =
      snake[0].Ypos <= 0 ? height - blockHeight : snake[0].Ypos - blockHeight;
    this.setState({ snake });
  }

  moveHeadRight() {
    let width = this.state.width;
    let blockWidth = this.state.blockWidth;
    let snake = this.state.snake;
    snake[0].Xpos =
      snake[0].Xpos >= width - blockWidth ? 0 : snake[0].Xpos + blockWidth;
    this.setState({ snake });
  }

  moveHeadDown() {
    let height = this.state.height;
    let blockHeight = this.state.blockHeight;
    let snake = this.state.snake;
    snake[0].Ypos =
      snake[0].Ypos >= height - blockHeight ? 0 : snake[0].Ypos + blockHeight;
    this.setState({ snake });
  }

  handleKeyDown(event) {
    if (this.state.isGameOver && event.keyCode === 32) {
      this.resetGame();
      return;
    }

    if (this.state.directionChanged) return;

    switch (event.keyCode) {
      case 37:
      case 65:
        this.goLeft();
        break;
      case 38:
      case 87:
        this.goUp();
        break;
      case 39:
      case 68:
        this.goRight();
        break;
      case 40:
      case 83:
        this.goDown();
        break;
      default:
    }
    this.setState({ directionChanged: true });
  }

  handleTouch(direction) {
    if (this.state.isGameOver) return;

    switch (direction) {
      case 'left':
        this.goLeft();
        break;
      case 'up':
        this.goUp();
        break;
      case 'right':
        this.goRight();
        break;
      case 'down':
        this.goDown();
        break;
      default:
        break;
    }
    this.setState({ directionChanged: true });
  }

  goLeft() {
    let newDirection = this.state.direction === 'right' ? 'right' : 'left';
    this.setState({ direction: newDirection });
  }

  goUp() {
    let newDirection = this.state.direction === 'down' ? 'down' : 'up';
    this.setState({ direction: newDirection });
  }

  goRight() {
    let newDirection = this.state.direction === 'left' ? 'left' : 'right';
    this.setState({ direction: newDirection });
  }

  goDown() {
    let newDirection = this.state.direction === 'up' ? 'up' : 'down';
    this.setState({ direction: newDirection });
  }

  

  render() {
    if (this.state.isGameOver) {
      return (
        <GameOver
          width={this.state.width}
          height={this.state.height}
          highScore={this.state.highScore}
          newHighScore={this.state.newHighScore}
          score={this.state.score}
        />
      );
    }

    return (
      <>
<h1
  style={{
    marginLeft: "10%",
    marginRight: "10%",
    textAlign: "center", // Center align for better responsiveness
    fontSize: "2rem", // Base font size for large screens
    fontWeight: "bold",
    lineHeight: "1.5",
    color: "#000",
    fontFamily: "'Roboto', sans-serif",
  }}
>
  Snake Game was my favorite game since childhood, here's a snake game for you coded by me!{" "}
  <span
    style={{
      display: "inline-block", // Inline block for styling
      fontSize: "1.6rem", // Slightly smaller text
      fontWeight: "bold",
      fontStyle: "italic",
      background:"#000",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      animation: "pulse 60s infinite", // Add a subtle pulsing animation
    }}
  >
    (My best score is 35 – email me for a prize if you beat it!)
  </span>
  <style>
    {`
      @keyframes pulse {
        0%, 100% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.05);
        }
      }
    `}
  </style>
</h1>
      <div
        id='GameBoard'
        style={{
          width: this.state.width,
          height: this.state.height,
          borderWidth: this.state.width / 50,
        }}>
        {this.state.snake.map((snakePart, index) => {
          return (
            <div
              key={index}
              className='Block'
              style={{
                width: this.state.blockWidth,
                height: this.state.blockHeight,
                left: snakePart.Xpos,
                top: snakePart.Ypos,
                background: this.state.snakeColor,
              }} />
          );
        })}
        <div
          className='Block'
          style={{
            width: this.state.blockWidth,
            height: this.state.blockHeight,
            left: this.state.apple.Xpos,
            top: this.state.apple.Ypos,
            background: this.state.appleColor,
          }} />
        <div id='Score' style={{ fontSize: this.state.width / 20 }}>
          HIGH-SCORE: {this.state.highScore}&ensp;&ensp;&ensp;&ensp;SCORE:{' '}
          {this.state.score}
        </div>
      </div><div className="touch-controls">
          <button onClick={() => this.handleTouch('up')}>&#8679;</button>
          <div>
            <button onClick={() => this.handleTouch('left')}>&#8678;</button>
            <button onClick={() => this.handleTouch('down')}>&#8681;</button>
            <button onClick={() => this.handleTouch('right')}>&#8680;</button>
          </div>
        </div></>
    );
  }
}

export default SnakeGame;
