import React from "react";
import { useParams } from "react-router-dom";
import { ProjectList } from "../helpers/ProjectList";
import GitHubIcon from '@mui/icons-material/GitHub';
import "../styles/ProjectDisplay.css";

function ProjectDisplay() {
  const { id } = useParams();
  const project = ProjectList[id];

  const githubLinks = {
    "react-native-vision-camera": "https://github.com/sepfazeli/react-native-vision-camera",
    "fingerspelling-ios": "https://github.com/sepfazeli/Fingerspelling-iOS",
  };

  const githubLink = githubLinks[project.name];

  return (
    <div className="project">
      <h1 className="project-title">{project.name}</h1>
      <div className="project-image-container">
        <img src={project.image} alt={`${project.name} project`} className="project-image" />
      </div>
      <p className="project-skills">
        <b>Skills:</b> {project.skills}
      </p>
      {githubLink && (
        <a href={githubLink} target="_blank" rel="noopener noreferrer" className="project-link">
          <GitHubIcon />
        </a>
      )}
    </div>
  );
}

export default ProjectDisplay;
