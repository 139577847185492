import React, { useEffect } from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import GitHubIcon from "@mui/icons-material/GitHub";
import "../styles/Home.css";
import react from "../assets/react.png";
import redux from "../assets/redux.png";
import htm from "../assets/html.png";
import css from "../assets/css.png";
import swift from "../assets/swift.png";
import ruby from "../assets/ruby.png";
import xd from "../assets/xd.png";
import figma from "../assets/figma.png";
import java from "../assets/java.png";
import python from "../assets/python.png";
import c from "../assets/c++.png";
import mongodb from "../assets/mongodb.png";
import mysql from "../assets/mySQL.png";
import nodejs from "../assets/nodejs.png";
import js from "../assets/js.png";
import numpy from "../assets/numpy.png";
import scikit from "../assets/scikit.png";
import pandas from "../assets/pandas.png";
import git from "../assets/git.png";
import jira from "../assets/jira.png";
import springboot from "../assets/springboot.png";
import fastapi from "../assets/fastapi.png";
import graphql from "../assets/graphql.png";
import yourPhoto from "../assets/sepi.png";
import sepipeipi from "../assets/sepipepi.png";
import Chatbot from "../components/Chatbot";

function Home() {
  useEffect(() => {
    const skillsSection = document.querySelector(".skills");

    const handleScroll = () => {
      const skillsSectionTop = skillsSection.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (skillsSectionTop < windowHeight) {
        document.querySelectorAll(".logo-container").forEach((container) => {
          container.classList.add("logo-appear");
        });

        // Remove the event listener once the logos have appeared
        window.removeEventListener("scroll", handleScroll);
      }
    };

    // Attach the event listener to the scroll event
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array ensures the effect runs only once

  return (
    <div className="home">
      <div className="about">
        <div className="photo-container">
          <img src={sepipeipi} alt="Sepehr Fazely" className="photo" />
        </div>
        <h2> Sepehr Fazely</h2>

        <div className="prompt">
          <p>
            A full stack software developer with a passion for learning and
            creating.
          </p>
          <a
            href="https://www.linkedin.com/in/sepehr-fazely/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon />
          </a>
          <a href="mailto:sepifazeli@yahoo.com">
            <EmailIcon />
          </a>
          <a
            href="https://github.com/sepfazeli"
            target="_blank"
            rel="noopener noreferrer"
          >
            <GitHubIcon />
          </a>
        </div>
      </div>
      <div className="skills">
      <Chatbot />

        <table className="table">
          <tbody>
            <tr>
              <td>
                <h2>Front-End and Mobile Development</h2>
                <div className="infinite-scroll">
                  <div className="logo-container">
                    <img src={react} alt="ReactJS Logo" className="logo" />
                    <p>ReactJS</p>
                  </div>
                  <div className="logo-container">
                    <img src={redux} alt="Redux Logo" className="logo" />
                    <p>Redux</p>
                  </div>
                  <div className="logo-container">
                    <img src={htm} alt="HTML Logo" className="logo" />
                    <p>HTML</p>
                  </div>
                  <div className="logo-container">
                    <img src={css} alt="CSS Logo" className="logo" />
                    <p>CSS</p>
                  </div>
                  <div className="logo-container">
                    <img src={swift} alt="Swift Logo" className="logo" />
                    <p>Swift</p>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <h2>Back-End</h2>
                <div className="infinite-scroll">
                  <div className="logo-container">
                    <img src={nodejs} alt="Node.js Logo" className="logo" />
                    <p>Node.js</p>
                  </div>
                  <div className="logo-container">
                    <img src={ruby} alt="Ruby Logo" className="logo" />
                    <p>Ruby</p>
                  </div>
                  <div className="logo-container">
                    <img
                      src={springboot}
                      alt="Spring Boot Logo"
                      className="logo"
                    />
                    <p>Spring Boot</p>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <h2>APIs</h2>
                <div className="infinite-scroll">
                  <div className="logo-container">
                    <img src={fastapi} alt="FastAPI Logo" className="logo" />
                    <p>FastAPI</p>
                  </div>
                  <div className="logo-container">
                    <img src={graphql} alt="GraphQL Logo" className="logo" />
                    <p>GraphQL</p>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <h2>Databases and Cloud Platforms</h2>
                <div className="infinite-scroll">
                  <div className="logo-container">
                    <img src={mysql} alt="MySQL Logo" className="logo" />
                    <p>MySQL</p>
                  </div>
                  <div className="logo-container">
                    <img src={mongodb} alt="MongoDB Logo" className="logo" />
                    <p>MongoDB</p>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <h2>Languages</h2>
                <div className="infinite-scroll">
                  <div className="logo-container">
                    <img src={python} alt="Python Logo" className="logo" />
                    <p>Python</p>
                  </div>
                  <div className="logo-container">
                    <img src={c} alt="C++ Logo" className="logo" />
                    <p>C++</p>
                  </div>
                  <div className="logo-container">
                    <img src={java} alt="Java Logo" className="logo" />
                    <p>Java</p>
                  </div>
                  <div className="logo-container">
                    <img src={js} alt="JavaScript Logo" className="logo" />
                    <p>JavaScript</p>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <h2>Machine Learning</h2>
                <div className="infinite-scroll">
                  <div className="logo-container">
                    <img src={numpy} alt="NumPy Logo" className="logo" />
                    <p>NumPy</p>
                  </div>
                  <div className="logo-container">
                    <img src={scikit} alt="Scikit Learn Logo" className="logo" />
                    <p>Scikit Learn</p>
                  </div>
                  <div className="logo-container">
                    <img src={pandas} alt="Pandas Logo" className="logo" />
                    <p>Pandas</p>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <h2>Other Tools</h2>
                <div className="infinite-scroll">
                  <div className="logo-container">
                    <img src={git} alt="Git Logo" className="logo" />
                    <p>Git</p>
                  </div>
                  <div className="logo-container">
                    <img src={jira} alt="JIRA Logo" className="logo" />
                    <p>JIRA</p>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Home;
